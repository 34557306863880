import { inject, observer } from "mobx-react";
import { Component } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { compose } from "recompose";

import { AdvertisementModal } from "nvent-web/components/AdvertismentModal/AdvertisementModal";
import { PromotionsStore } from "nvent-web/stores/Promotions";

interface AdvertisementsInnerProps extends InjectedIntlProps {
  promotions: PromotionsStore;
}

interface AdvertisementsState {
  showAdvertisementModal: boolean;
}

class AdvertisementsInner extends Component<AdvertisementsInnerProps, AdvertisementsState> {
  state = {
    showAdvertisementModal: true,
  };

  render() {
    const { promotions } = this.props;

    if (promotions.arePromotionsLoading) {
      return null;
    }

    const advertisementPopup = promotions.promotionsForCurrentUser.find((promotion) => promotion.popup);

    if (!advertisementPopup) {
      return null;
    }

    const advertisementName = `mute.offer.${advertisementPopup.countryCode}.${advertisementPopup.id}`;
    const advertisementDisabledInLocalStorage = localStorage.getItem(advertisementName) !== null;

    const image = advertisementPopup.images && advertisementPopup.images.find(({ type }) => type === "popup");

    if (advertisementDisabledInLocalStorage) {
      return null;
    }

    return (
      <AdvertisementModal
        advertisementName={advertisementName}
        isOpen={this.state.showAdvertisementModal}
        handleClose={this.closeAdvertisementModal}
        imageSrc={image ? image.urls.large : ""}
        popupUrl={advertisementPopup.popupUrl}
        center
      />
    );
  }

  closeAdvertisementModal = (advertisementName: string, showAgain: boolean) => {
    this.setState({ showAdvertisementModal: false });
    if (showAgain) {
      localStorage.setItem(advertisementName, "");
    }
  };
}

export const Advertisements = compose<AdvertisementsInnerProps, Record<string, unknown>>(
  injectIntl,
  inject("promotions"),
  observer
)(AdvertisementsInner);
