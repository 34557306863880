import { Component } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { compose } from "recompose";

import { PrimaryBlueButton } from "../Button";
import { Checkbox } from "../Checkbox/Checkbox";
import Modal, { ModalProps } from "../Modal";

import style from "./AdvertisementModal.module.scss";

interface AdvertisementModalProps extends Omit<ModalProps, "handleClose"> {
  advertisementName: string;
  imageSrc?: string;
  imageAlt?: string;
  popupUrl?: string;
  handleClose: (advertisementName: string, dontShowAgain: boolean) => void;
}
interface AdvertisementModalInnerProps extends InjectedIntlProps, AdvertisementModalProps {}

class AdvertisementModalInner extends Component<AdvertisementModalInnerProps> {
  state = {
    dontShowAgain: false,
  };

  onClose = () => {
    this.props.handleClose(this.props.advertisementName, this.state.dontShowAgain);
  };

  onDontShowAgainCheckboxChange = (checked: boolean) => {
    this.setState({ dontShowAgain: checked });
  };

  render() {
    const { imageSrc = "", imageAlt, popupUrl, ...modalProps } = this.props;

    const image = imageSrc ? <img src={imageSrc} className={style.image} alt={imageAlt} /> : null;

    return (
      <Modal {...modalProps} handleClose={this.onClose} bodyClass={style.modal}>
        <div className={style.wrapper}>
          {popupUrl ? (
            <a href={popupUrl} target="_blank" rel="noopener noreferrer" onClick={this.onClose}>
              {image}
            </a>
          ) : (
            <Link to="/certifiedpro#offers" onClick={this.onClose}>
              {image}
            </Link>
          )}
          <div className={style.buttonWrapper}>
            <Checkbox
              value={this.state.dontShowAgain}
              onChange={this.onDontShowAgainCheckboxChange}
              name="disable-advertisment-modal-checkbox"
              label={<FormattedMessage id="advertismentModal.checkboxText" />}
            />
            <PrimaryBlueButton className={style.button} onClick={this.onClose}>
              <FormattedMessage id="advertismentModal.buttonText" />
            </PrimaryBlueButton>
          </div>
        </div>
      </Modal>
    );
  }
}

export const AdvertisementModal = compose<AdvertisementModalInnerProps, AdvertisementModalProps>(injectIntl)(
  AdvertisementModalInner
);
